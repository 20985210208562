import '../Styles/Calendar.css'



export default function Calendar() {
        return (
                <>
                     <div className="Calendar">
                        <p id='calendarTitle'>Get Involved!</p>
                         <iframe id='calendar' src="https://docs.google.com/document/d/1ni3EJEV9b0xgfAWznHRT7aiZb3lOcrALE22lSakIgW0/edit?usp=sharing"  title="description"></iframe>

                     </div>   
                </>
        );
}               